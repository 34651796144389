import React from 'react';
import YourPDFFile from '../assets/boryana-gonkova-cv.pdf';
import Button from './Button';

const DownloadLink = () => {
  const handleOpenInNewTab = () => {
    // Отваряне в нов раздел
    window.open(YourPDFFile, '_blank');
  };

  return (
    <>
      <Button
        title='Download CV'
        onClick={handleOpenInNewTab} />
    </>
  );
};

export default DownloadLink;
