import React from 'react';

import './About.css';
import MySkills from '../../components/MySkilss';

export default function About() {
    return (
        <div className='about-info' >
            <div className='about-text-first'>
                <h1>About me </h1>
                <p >
                    Here you will find more information about me, what I do,
                    and my current skills mostly
                    in terms of programming and technology
                </p>
            </div>
            <div className='container-about-items'>
                <div className='container-about-items-left'>
                    <div className='about-text'> Get to know me!</div>
                    <p style={{ marginTop: 40 }}>
                        I'm a Frontend Web Developer building the Front-end of Websites and Web Applications
                        that leads to the success of the overall product.
                        I'm open to Job opportunities where I can contribute, learn and grow. If you have a good opportunity
                        that matches my skills and experience then don't hesitate to contact me.
                    </p>
                </div>
                <div className='container-about-items-right'>
                    <MySkills />
                </div>
            </div>
        </div>
    );
}
