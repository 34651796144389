import React from 'react';
import { SiGithub, SiLinkedin, SiFacebook } from 'react-icons/si';

export default function SocialIcons() {
  const [hoveredIcon, setHoveredIcon] = React.useState(null);

  const iconContainerBaseStyle = {
    background: 'rgba(255, 255, 255, 1)',
    borderRadius: '10px',
    padding: '7px',
    boxShadow: '0px 17px 31px 0px rgba(53, 53, 53, 0.95)',
    transition: 'transform 0.3s ease',
  };

  const handleIconHover = (icon) => {
    setHoveredIcon(icon);
  };

  const handleIconHoverExit = () => {
    setHoveredIcon(null);
  };

  return (
    <>
      <a 
        href='https://github.com/gonkova' 
        target='_blank' 
        rel='noopener noreferrer' 
        aria-label='GitHub profile of Boryana Gonkova' 
        style={{
          ...iconContainerBaseStyle,
          transform: hoveredIcon === 'github' ? 'scale(1.1)' : 'scale(1)',
        }}
        onMouseEnter={() => handleIconHover('github')}
        onMouseLeave={handleIconHoverExit}
      >
        <SiGithub size={35} style={{ color: 'black' }} />
      </a>
      <a 
        href='https://www.linkedin.com/in/boryana-gonkova-9b4823266/' 
        target='_blank' 
        rel='noopener noreferrer' 
        style={{
          ...iconContainerBaseStyle,
          transform: hoveredIcon === 'linkedin' ? 'scale(1.1)' : 'scale(1)',
        }}
        onMouseEnter={() => handleIconHover('linkedin')}
        onMouseLeave={handleIconHoverExit}
      >
        <SiLinkedin size={35} style={{ color: '#0a66c2' }} />
      </a>
      <a 
        href='https://www.facebook.com/gonkova?locale=bg_BG' 
        target='_blank' 
        rel='noopener noreferrer' 
        style={{
          ...iconContainerBaseStyle,
          transform: hoveredIcon === 'facebook' ? 'scale(1.1)' : 'scale(1)',
        }}
        onMouseEnter={() => handleIconHover('facebook')}
        onMouseLeave={handleIconHoverExit}
      >
        <SiFacebook size={35} style={{ color: '#0866ff' }} />
      </a>
    </>
  );
}
