import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';


export default function ContactForm() {

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...FormData, [name]: value });
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert('message sent successfully...');
          console.log(result.text);
          setFormData({
            user_name: '',
            user_email: '',
            user_message: '',
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className='container-contactform'>
      <form className='cf' ref={form} onSubmit={sendEmail}>
      <div style={{ fontSize: 24, color: 'rgb(25,36,57)'}}>Send me a message</div>
        <div className='half left cf'>
          <input
            type='text'
            placeholder='Name'
            name='user_name'
            value={formData.user_name}
            onChange={(e) => handleChange(e)}
            required
          />
          <input
            type='email'
            placeholder='Email address'
            name='user_email'
            value={formData.user_email}
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        <div className='half right cf'>
          <textarea
            name='message'
            type='text'
            placeholder='Message'
            value={formData.user_message}
            onChange={(e) => handleChange(e)}
            rows="4"
            required
          >
          </textarea>
        </div>
        <input
          type='submit'
          value='Submit'
          id='input-submit'
        />
      </form>
    </div>
  );
}