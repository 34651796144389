import React from 'react';
import EducationInfo from '../../components/EducationInfo';

export default function Education() {
    return (
        <div >
            <EducationInfo />
        </div>
    );
}

