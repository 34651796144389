// Footer.js
import React, { useEffect, useState } from 'react';
import './Footer.css';
export default function Footer() {
    const [isHomePage, setIsHomePage] = useState(true);

    useEffect(() => {
        // Проверка за началната страница
        const checkHomePage = () => {
            setIsHomePage(window.location.pathname === '/');
        };

        // Извиква се при първоначален рендър и при промяна на пътя
        checkHomePage();

        // Прикрепяне на слушател за промяна на пътя
        const handlePathChange = () => {
            checkHomePage();
        };

        window.addEventListener('hashchange', handlePathChange);

        // Почистване на слушателя при размонтиране на компонента
        return () => {
            window.removeEventListener('hashchange', handlePathChange);
        };
    }, []);

    if (!isHomePage) {
        return null; // Скриване на футъра, ако не сте на началната страница
    }

    return (
        <footer>
            &copy; Copyright 2024. Made by Boryana Gonkova
        </footer>
    );
}



