import React from 'react';
import './EducationInfo.css';

export default function EducationInfo() {
    return (
        <div className='container-education-info'>
            <div className='container-education-text'>
                <h1 className='h1-gradient-text'>EDUCATION</h1>
                <p>
                    My journey as a developer started with obtaining
                    solid knowledge of HTML, CSS and JavaScript. Through using these,
                    I learned how to create and apply JavaScript variables, conditional constructions,
                    QuerySelector, cycles, arrays and other techniques needed for websites development.
                    Moving further, I enhanced my education with basing principles of React with ability
                    to create and combine multiple components in complete web applications. I developed
                    my ability to write clean and efficient code, which enabled me to create dynamic and
                    interactive web experience.
                </p>
            </div>
            <div className='certificate-container'>
                <div className='certificate'>
                    <img src={'/images/certificate.jpg'} alt='Certificate' className='certificate-image' />
                </div>
                <div className='certificate'>
                    <img src={'/images/certificate-react.jpg'} alt='Certificate React' className='certificate-image' />
                </div>
            </div>
        </div>
    );
}