import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function WithMainLayout(Component) {
    function layout(props) {
        return (
            <div style={{ margin: 0, padding: 0, display: 'flex', flexDirection: 'column' }}>
                <Header />
                <div className='container' style={{ flex: 1 }}>
                    <div >
                        <Component {...props} />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
    return layout;
}
