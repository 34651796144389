import React from 'react';
import './Contacts.css';
import ContactForm from '../../components/ContactForm';
import SocialIcons from '../../components/SocialIcons';
import CallMe from '../../components/CallMe';


export default function Contacts() {
    return (
        <div>
            <h1>Contact</h1>
            <div className='main-container-contacts'>
                <div className='container-contacts-left'>
                    <div className='call-me'>
                        <div style={{textAlign: 'center', fontSize: 24, color: 'rgb(25,36,57)', marginBottom: 15}}>Contact me</div>
                        <CallMe />
                    </div>
                    <div className='container-contacts-icons'>
                        <SocialIcons />
                    </div>
                </div>
               
                <div className='container-contacts-right'>
                    <ContactForm />
                </div>
            </div>
        </div>
    );
}
