import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '../components/Menu';

export default function Header() {
    return (
        <div >
            <Menu />
        </div>
    );
}