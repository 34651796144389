import React from 'react';
import { SiJavascript, SiReact, SiBootstrap, SiHtml5, SiCss3 } from 'react-icons/si';
import './MySkills.css';

export default function MySkills() {
  return (
    <div className='container-skilss'>
      <div className='about-text'>My skills</div>
      <div className="skills-container" style={{marginTop: '9%'}}>
        <SiHtml5 className="icon html-icon" />
        <SiCss3 className="icon css-icon" />
        <SiJavascript className="icon js-icon" />
        <SiBootstrap className="icon bootstrap-icon" />
        <SiReact className="icon react-icon" />
      </div>
    </div>
  );
}



