import React from 'react';
import WithMainLayout from '../../layout/WithMainLayot';
import './Home.css';
import './HomeMobile.css';
import DownloadLink from '../../components/DownloadLink';
import Contacts from '../../pages/contacts/Contacts';
import SocialIcons from '../../components/SocialIcons';
import Education from '../education/Education';
import About from '../about/About';


function Home() {
  return (
    <div className='container-main'>
      <div className='container-home' id="home-section">
        <div style={{ marginTop: '60%' }}>
        </div>
        <div className='container-home-left' >
          <div className='text-bo' id="animated-text" >
            Hello, I'm Boryana Gonkova
          </div>
          <div className='container-z' >
            <div className="circle-container" >
              <img src={'/images/profile.jpg'} alt='Profile' className='profile-image' />
            </div>
          </div>
        </div>
        <div className='container-home-right'>
          <div className='animated-gradient-text' id='text-front-end'>
            FRONT-END DEVELOPER
          </div>
          <div className='text-developer' >
            A programmer with experience in building personal websites and
            Apps with  HTML/ CSS /JavaScript / React 
          </div>
          <div className='home-button'>
            <DownloadLink />
            <div className='home-social-icons'>
              <SocialIcons />
            </div>
          </div>
        </div>
      </div>
      <div className='container-education' id="education-section">
        <Education />
      </div>
      <div className='container-about' id='about-section'>
        <About />
      </div>
      <div className='container-contacts' id='contacts-section'>
        <Contacts />
      </div>
    </div>
  );
}
export default WithMainLayout(Home);