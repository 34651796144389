import React, { useState } from 'react';

export default function Button(props) {
    const [isHovered, setIsHovered] = useState(false);

    const btnStyles = {
        border: 'unset',
        borderRadius: '8px',
        boxShadow: '0px 17px 31px 0px rgba(53,53,53,0.95)', 
        padding: props.padding ?? '10px 30px',
        textTransform: 'uppercase',
        color: props.color ?? 'white',
        background: props.background ?? 'rgb(25,36,57)',
        opacity: isHovered ? 0.3 : 1,
        cursor: 'pointer'
    }
    return (
        <>
            <button
                type={props.type === "submit" ? "submit" : "button"} // Задаване на type submit, ако е изрично посочено
                style={btnStyles}
                onClick={props.onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {props.title}
            </button>
        </>
    );
}