import React, { useState, useRef } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Menu.css';

export default function Menu() {
    const MENU_ITEMS = [
        { link: 'home-section', title: 'Home' },
        { link: 'education-section', title: 'Education' },
        { link: 'about-section', title: 'About' },
        { link: 'contacts-section', title: 'Contacts' }
    ];

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuItemClick = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className='menu'>
            <div className='logo-container'>
            <img src={'./images/logo.png'} alt='Logo' className='logo' />
            </div>
            <nav>
                <button className='d-hide' id='button-menu' aria-label='Open menu'  onClick={handleToggleMenu}>
                    {isMenuOpen ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}
                </button>
                <ul className={!isMenuOpen && 'm-hide'} >
                    {MENU_ITEMS.map((item, index) => (
                        <li key={index} className='menu-item'>
                            <ScrollLink
                                to={item.link}
                                smooth={true}
                                duration={500}
                                offset={-150}
                                onClick={
                                    handleMenuItemClick}
                            >
                                {item.title}
                            </ScrollLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}
