import React from 'react';
import { MdPhone, MdEmail } from 'react-icons/md';
import './CallMe.css';

export default function CallMe() {
    const phoneNumber = '+359889272624';
    const myEmail = 'borqnagonkova@gmail.com';


    return (
        <div className='container-call'>
            <div className="hero-circle"></div>
            <div className="circle-large"></div>
            <div>
                <a href={`mailto:${myEmail}`} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10 }}>
                    <MdEmail size={40} style={{ marginRight: 10, color: 'rgb(8,102,255)' }} />
                    <span style={{color: 'rgb(25,36,57)', fontSize:18}}>borqnagonkova@gmail.com</span>
                </a>
            </div>

            <div>
                <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10 }}>
                    <MdPhone size={40} style={{ marginRight: 10, color: 'rgb(52,168,83)' }} />
                    <span style={{color: 'rgb(25,36,57)', fontSize: 18}}>+359 88 927 2624</span>
                </a>
            </div>
        </div>
    );
}